.termsPoint {
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 30px 80px 80px 80px !important;
}
.termsheader {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #363636;
    margin-bottom: 15px;
    margin-top: 50px;
}
.terms-paragraph {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 175%;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #555555;
    margin: 0 0 15px 0;
}

.term-new {
    margin: 80px 0;
}

.termsConditions {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 56px;
    text-align: center;
    text-transform: capitalize;
    color: #363636;
    margin: 0 0 80px 0;
}




@media(max-width: 767px){
    .termsConditions {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: justify;
        text-transform: capitalize;
        color: #363636;
        margin: 0 0 24px 0;
        text-align: center;
    }
    .termsConditions {
        font-weight: 700;
        font-size: 26px;
        line-height: 24px;
        text-align: justify;
        text-transform: capitalize;
        color: #363636;
        margin: 0 0 24px 0;
        text-align: center;
    }
    .terms-paragraph {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 150%;
        text-align: left;
        color: #363636;
    }
    .termsheader {
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        /* text-align: justify; */
        text-transform: capitalize;
        color: #363636;
        margin: 0 0 12px 0;
    }
    .termsPoint {
        padding: 22px 22px 16px 22px!important;
    }
    .term-new {
        margin: 50px 0;
    }
}