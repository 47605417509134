

.email_heading {
    font-size: 25px;
    color: #a5acc5;
    padding: 10px 20px 0 20px;
}

.emailveryfy {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    border-radius: 0;
    background: #141518;
    display: flex;
    align-items: center;
    justify-content: center;
}
.center-db {
    background: #3c4254;
    padding: 30px;
    border-radius: 6px;
}
.center-db img {
    max-width: 110px;
    margin: auto;
    display: block;
}

.email_heading p {
    margin: 0;
}

.logintxt {
    text-transform: capitalize;
}