.historyPageHeading {
  font-weight: 700;
  letter-spacing: 1.2px;
}

.tb-cus tr td {
  vertical-align: middle;
}

.child {
  background-color: #363636;
  color: white;
}

.child:hover,
.child:active {
  background-color: #4d4141;
  color: white;
}

.scroll-tbl {
  overflow: auto;
}

.scroll-tbl table {
  min-width: 500px;
}

.img-size {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.react-responsive-modal-modal {
  margin: 0;
}


.pagination li a {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 23.2971px;
  line-height: 33px;
  color: #1D2939;
  border: 1.66408px solid #D0D5DD;
  padding: 15px 20px;
  min-width: 66px;
  text-align: center;
  background-color: #ffffff;
}

.pagination li:last-child a {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

.pagination li:first-child a {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  display: inline-block;
  margin: 0 0px;
}

.pagination a {
  display: block;
  padding: 5px 10px;
  background-color: #f2f2f2;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.pagination li.active a {
  background-color: #F9FAFB;
  color: #1D2939;
}

.pagination a:hover {
  background-color: #ddd;
}



.family-new {
  padding: 80px 0;
}

.text-center.mt-3.mb-1.historyPageHeading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 56px;
  text-align: justify;
  text-transform: capitalize;
  color: #363636;
  margin: 0 0 30px 0 !important;
}

.scroll-tbl.new-familytable table thead tr th {
  background: #363636;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: justify;
  text-transform: capitalize;
  color: #FFFFFF;
  padding: 15px 70px;
  height: 114px;
  vertical-align: middle;
}

.scroll-tbl.new-familytable table thead tr th:first-child {
  border-top-left-radius: 16px;
}

.scroll-tbl.new-familytable table thead tr th:last-child {
  border-top-right-radius: 16px;
}

.ey {
  width: 40px;
}

.scroll-tbl.new-familytable table tbody tr td {
  background: #ffffff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: justify;
  text-transform: capitalize;
  color: #FFFFFF;
  padding: 30px 70px;
  vertical-align: middle;
  border: none;
}

.scroll-tbl.new-familytable table tbody tr:nth-child(even) td {
  background: #F7F0F0;
}

.td-date {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
  color: #555555;
  display: inline-block;
  height: 46px;
  line-height: 46px;
  min-width: 160px;
  background: #F7F0F0;
  border-radius: 4px;
  text-align: center;
  margin: 0;
}

.scroll-tbl.new-familytable table tbody tr:nth-child(even) td .td-date {
  background: #FFFFFF;
}

/* .allData {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
  color: #555555;
  display: inline-block;
  background: #FFFFFF;
  border-radius: 4px;
  height: 46px;
  line-height: 46px;
  min-width: 160px;
  text-align: center;
  margin: 0;
} */
.scroll-tbl.new-familytable table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 16px;
}

.scroll-tbl.new-familytable table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 16px;
}

.pagination li a:hover {
  background: black;
  color: #fff !important;
}

.pagination li a {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 23.2971px;
  line-height: 33px;
  color: #1D2939;
  border: 1.66408px solid #D0D5DD;
  padding: 15px 20px;
  min-width: 66px;
  text-align: center;
}

.pagination li:last-child a {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

.pagination li:first-child a {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}

.pagination li:first-child a p {
  margin: 0;
}

.pagination li:first-child a p i {
  margin: 0 10px 0 0px;
  font-size: 20px;
}

.pagination li:last-child a p {
  margin: 0;
}

.pagination li:last-child a p i {
  margin: 0 0px 0 10px;
  font-size: 20px;
}

.image-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.download-sec {
  display: flex;
  height: 201px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.slider-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.slider-download {
  padding: 18px 80px;
  gap: 10px;
  background: #363636;
  border-radius: 4px;
  color: white;
  text-decoration: none;
  font-size: 22px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.share-slider {
  position: relative;
}
.share-slider:after {
  position: absolute;
  left: -70px;
  top: 13px;
  width: 60px;
  height: 1px;
  background: grey;
  content: "";
}
.share-slider:before {
  position: absolute;
  right: -70px;
  top: 13px;
  width: 60px;
  height: 1px;
  background: grey;
  content: "";
}
.slider-prev {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border: none;
  background: #363636;
  color: #fff;
  border-radius: 4px;
}
.react-responsive-modal-modal {
  padding: 50px 80px;
  max-width: 900px;
}
.react-responsive-modal-modal {
  border-radius: 12px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.slider-next {
  display: flex;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: none;
  background: #363636;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.image-sec .disabled {
background-color: #ddd;
}
.Demo__some-network button {
  margin: 0 5px;
}
.share-slider {
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.slider-download:hover {
  color: #fff;
}



@media only screen and (max-width: 991px) {
  .pagination li:first-child a p i {
    margin: 0 5px 0 0px;
    font-size: 17px;
    position: relative;
    top: 1px;
  }

  .pagination li:last-child a p i {
    margin: 0 0px 0 5px;
    font-size: 17px;
    position: relative;
    top: 1px;
  }

  .pagination li a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
    color: #1D2939;
    border: 1.66408px solid #D0D5DD;
    padding: 0px 10px;
    min-width: 28px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .react-responsive-modal-modal {
    margin: 0;
    max-width: 95%;
    padding: 50px 20px;
  }
.slider-prev {
padding: 8px 20px;
}

.slider-next{
  padding: 8px 36px;
}

.slider-download{
  padding: 12px 40px;
  margin-top: 15px;
}
.Demo__some-network {
  margin-right: 10px;
}
.download-sec{
  height: unset;
  gap: 0;
}
.share-slider {
  margin: 30px 0 20px 0;
}
  .text-center.mt-3.mb-1.historyPageHeading {
    font-size: 26px;
    line-height: 24px;
    margin: 0 0 0 0 !important;
  }

  .family-new {
    padding: 50px 0;
  }

  .scroll-tbl.new-familytable table thead tr th {
    font-size: 18px;
    line-height: 17px;
    height: 51px;
    padding: 5px 30px;
  }

  .img-size {
    width: 50px;
    height: 50px;
  }

  .scroll-tbl.new-familytable table tbody tr td {
    height: 56px;
    font-size: 14px;
    line-height: 17px;
    padding: 5px 30px;
  }

  .scroll-tbl.new-familytable table thead tr th:first-child {
    border-top-left-radius: 4px;
  }

  .scroll-tbl.new-familytable table thead tr th:last-child {
    border-top-right-radius: 4px;
  }

  .scroll-tbl.new-familytable table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }

  .scroll-tbl.new-familytable table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }

  .td-date {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    min-width: 90px;
    border-radius: 4px;
  }

  .ey {
    width: 20px;
  }

  .scroll-tbl table {
    min-width: 640px;
  }


}