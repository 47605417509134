.fade.modal.show {
    z-index: 9999;
}
.modal-dialog.test-class.centered.modal-xl{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    transform: translate(0, 0%) !important;
    z-index: 999;
    width: 100%;
    height: auto !important;
    max-width: 400px;
    padding: 0 0 20px 0;
}
.modal-dialog.test-class.centered.modal-xl .login-2 {
    height: auto !important;
    min-height: auto !important;
}
body.modal-open {
    overflow: hidden !important;
}