.sub-1 {
    /* text-transform: uppercase; */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #555555;
    margin: 0 0 30px 0;
}
.sub-2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    display: flex;
    align-items: center;
    color: #363636;
    margin: 0 0 15px 0;
}
.sub-3 {
    margin: 0 0 30px 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    display: flex;
    align-items: center;
    color: #363636;
    position: relative;
    padding: 30px 0 0 0;
}
.subscription {
    padding: 80px 0;
}
.monthly-ul-1 li img {
    width: 100px;
    border-radius: 50px;
    object-fit: cover;
}
.monthly-ul-1 {
    display: flex;
    margin: 0 0 30px 0;
    display: none;
}
.monthly-ul-1 li {
    margin: 0 15px 0 0;
}
.monthly-ul li {
    font-size: 14px;
    margin: 0 0 15px 0;
}
.monthly-ul li i {
    margin: 0 10px 0 0;
}
.subscription-card-text-bigger {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    position: relative;
    color: #555555;
    padding: 0 0 0 30px;
    margin: 0 0 15px 0 !important;
}
.Get-Started {
    appearance: none;
    background-color: #363636;
    border: 2px solid #363636;
    border-radius: 12px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    min-height: 55px;
    min-width: 0;
    outline: none;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    will-change: transform;
    margin: 0 0 0px 0;
}
.Get-Started:hover {
    box-shadow: none;
    transform: translateY(0px);
    background-color: #363636 !important;
    border: 2px solid #ffffff !important;
    background: #ffffff !important;
}
.monthly-ul li i {
    background: #28C225;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    text-align: center;
    line-height: 20px;
    position: absolute;
    top: 4px;
    margin: 0 !important;
    left: 0;
}

.subscription-common {
    padding: 30px 20px;
    margin: 0 0 30px 0;
    height: 100%;
    padding-bottom: 80px !important;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding-bottom: 110px !important;
}

.subscription-common1 {
    background: #fff;
    border-radius: 26px;
    padding: 30px;
    margin: 0 0 30px 0;
    box-shadow: #363636 1px 5px 8px;
    height: 99%;
    padding-bottom: 80px !important;
    position: relative;
}
.subscription-common1:hover {
    outline: 4px solid #363636;
}
.subscription-common:hover {
    outline: 4px solid #363636;
}
.monthly-ul {
    margin: 0 0 30px 0px;
}
/* .width-max {
    max-width: 900px;
    margin: auto;
} */



.subscription-common button.Get-Started {
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
    margin: auto;
    width: auto;
    height: 69px;
    background: #363636;
    border-radius: 4px;
    max-width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.strikePrice {
    margin-left: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: #555555;
}
.subscription.subscription-new .subscription-common, .subscription.subscription-new .subscription-common1 {
    min-height: 738px;
    margin-bottom: 30px !important;
    padding-bottom: 30px !important;
    max-height: 660px;
}

@media(max-width: 1399px){
.subscription.subscription-new .subscription-common, .subscription.subscription-new .subscription-common1 {
    min-height: 800px;
    max-height: 800px;
}
}


@media(max-width: 1024px){
    .subscription.subscription-new .subscription-common, .subscription.subscription-new .subscription-common1 {
        max-height: 840px;
        min-height: 840px;
    }
}

@media(max-width: 991px){
    .subscription-common {
        height: auto;
        padding-bottom: 110px !important;
    }
    .width-max .col-lg-4.mb-3 {
        margin: 0 !important;
    }
    .subscription.subscription-new .subscription-common, .subscription.subscription-new .subscription-common1 {
        max-height: auto;
        min-height: auto;
        height: auto;
        padding-bottom: 15px !important;
    }
    .subscription.subscription-new button.Get-Started {
        margin: auto;
        display: block;
        padding: 0 15px;
        margin-bottom: 15px;
        margin-top: 25px;
        max-width: 170px;
        }
    }
    


    @media(max-width: 991px){
    .subscription.subscription-new .subscription-common, .subscription.subscription-new .subscription-common1 {
        max-height: 100% !important;
        min-height: 630px;
        height: 100% !important;
        padding-bottom: 15px !important;
    }
}