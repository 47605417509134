.copyright {
    background: #363636;
    padding: 15px 0;
    background: #363636;
    padding: 15px 0;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 9999;
}

.footerConditions {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
   
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 30px;
  
}

img.dd {
    border-radius: 31px;
    border: black;
    margin: 25px 0px;
}