.progress-indicator {
    display: flex;
    flex-direction: row;
    padding: 0;
    list-style: none;
  }
  
  .progress-step {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: auto;
    position: relative;
  }

  :root {
    --black: #363636;
    --white: #ffffff;
    --purple: #363636;
    --gray: #D8D8D8;
  }
  
  .progress-step .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid transparent;
    background: transparent;
    border-radius: 50%;
    padding: 20px;
    height: 80px;
    width: 80px;
    font-size: 24px;
    z-index: 1;
    color: var(--purple);
    transition: transform 0.5s ease 0.5s, background 0.5s ease, border-color 0.5s ease;
  }
  
  .progress-step.incomplete .step-number {
    background: #eeeeee;
    border-color: var(--gray);
    color: var(--black);
  }
  
  .progress-step.active .step-number {
    background: var(--white);
    border-color: var(--purple);
    color: var(--purple);
    transform: scale(1.2);
  }
  
  .progress-step.complete .step-number {
    background: var(--purple);
    color: var(--white);
  }
  
  .progress-step h3 {
    margin: 20px 0 0;
    color: var(--gray);
  }
  
  .progress-step.active h3 {
    color: var(--purple);
  }
  
  .progress-step.complete h3 {
    color: var(--black);
  }


  .progress-step:not(:last-child):before,
.progress-step:not(:last-child):after {
  content: '';
  position: absolute;
  height: 4px;
  left: 50%;
  top: 35px;
}

.progress-step:before {
  width: 100%;
  background: var(--gray);
}


.progress-step.complete:after {
    background: var(--purple);
    animation: progressWidth 0.5s ease forwards;
  }
  
  .progress-step.incomplete:after {
    background: var(--purple);
    animation: progressWidthReverse 0.5s ease forwards;
  }
  
  @keyframes progressWidth {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  
  @keyframes progressWidthReverse {
    0% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
  }


  .actions {
    display: flex;
    justify-content: center;
  }
  
  .progress-button {
    background: none;
    cursor: pointer;
    border: 1px solid var(--purple);
    font-size: 18px;
    padding: 18px;
    color: var(--purple);
    font-weight: 700;
    margin: 0 10px;
    min-width: 200px;
    border-radius: 8px;
  }